@import 'styles/import.scss';

.courseDetails {
  padding-top: 16px;
  border-top: 1px solid $grey25;
}

.label {
  @include text-xs;
  margin-bottom: 4px;
  color: $grey200;
  font-family: $font-mono;
}

.field {
  @include text-sm;
}

.informationContainer {
  padding-bottom: 16px;
  border-bottom: 1px solid $grey25;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  span {
    color: $blue_light;
    font-size: 28px;
  }
  &:last-child {
    margin-bottom: 0;
    border: 0;
    padding-bottom: 0;
  }
}

.filledButton {
  width: 100%;
  margin-bottom: 24px;
}

.button {
  width: 100%;
  margin-top: 32px;
  margin-bottom: 8px;
}

.link {
  margin-top: 16px;
  @include media-breakpoint-up(sm) {
    margin-top: 24px;
  }
}

.informationWrapper {
  margin-bottom: 80px;
  @include media-breakpoint-up(sm) {
    margin-left: auto;
    @include make-col(4, 6);
  }
  @include media-breakpoint-up(md) {
    @include make-col(5, 12);

    height: fit-content;
    margin-left: 0;
    margin-bottom: 0;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4, 12);
    @include make-col-offset(1, 12);
  }
}

.informationBox {
  padding: 24px;
  background: $grey1;
}

.title {
  @include heading-3;
  margin-top: 56px;
  margin-bottom: 80px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 120px;
    @include make-col(5, 6);
  }
  @include media-breakpoint-up(md) {
    @include make-col(8, 12);
  }
}

.text {
  ul {
    @include formatted-li;
  }

  ol {
    padding-left: 18px;
    list-style: auto;
  }

  h6 {
    @include text-xl;
    margin-bottom: 16px;
  }
}

.row {
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.textWrapper {
  @include media-breakpoint-up(sm) {
    @include make-col(4, 6);
    margin-left: auto;
  }
  @include media-breakpoint-up(md) {
    @include make-col(6, 12);
  }
}

.appointmentsButton {
  margin-top: 32px;
  svg {
    transform: rotate(90deg);
  }
  @include media-breakpoint-up(sm) {
    margin-top: 40px;
  }
}
