@mixin tab {
  width: fit-content;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 32px;
  border: 1px solid $green_light;
  color: $green_light;
  background-color: $white;

  @include tn(color background);
  font-weight: 600;
  @include text-sm;
  &:hover,
  &:focus-visible {
    border-color: $green;
    color: $green;
    outline: 0;
  }

  &.isSelected {
    background: $green_light;
    color: $white;
    &:hover {
      border-color: transparent;
      background-color: $green;
    }
  }
}
