@import 'styles/import.scss';

.image {
  @include make-col(3, 4);
  margin-left: auto;
  margin-bottom: 40px;
  @include media-breakpoint-up(md) {
    @include make-col(4, 4);
  }
}

.title {
  @include heading-6;
  margin-bottom: 24px;
}

.subtitle {
  @include text-lg;
  margin-bottom: 8px;
}

.text {
  margin-bottom: 24px;
  p {
    margin-bottom: 0;
  }
}

.location {
  @include media-breakpoint-up(md) {
    @include make-col(4, 6);
    margin-left: auto;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4, 12);
    padding: 0 12px;
  }
  @include media-breakpoint-up(xxl) {
    padding: 0 16px;
  }
}

.locationContainer {
  @include media-breakpoint-up(md) {
    @include make-col(3, 4);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4, 4);
  }
}

.locationsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    margin: 0 -12px;
  }
  @include media-breakpoint-up(xxl) {
    margin: 0 -16px;
  }
}
