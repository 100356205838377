@import 'styles/import.scss';

.textContainer {
  padding: 6px 12px 11px 16px;
  @include media-breakpoint-up(md) {
    padding: 24px;
  }
}

.slider {
  :global {
    .slick-slider[dir='ltr'] {
      @include media-breakpoint-up(md) {
        display: block;
        @include make-col-offset(1, 6);
      }
      @include media-breakpoint-up(lg) {
        @include make-col-offset(1, 12);
      }
      @include media-breakpoint-up(xxl) {
        margin-left: auto;
      }
    }
    .slick-arrow {
      width: 40px;
      height: 40px;
      bottom: -13px;
      top: calc(100% + 48px);
      z-index: 2;

      svg * {
        @include tn(stroke, 0.5s, 0s, ease-in-out);
      }

      &::before {
        content: '';
      }

      &:hover {
        svg * {
          stroke: $green;
        }
      }

      &.slick-disabled {
        svg * {
          stroke: $grey25;
        }
      }
      @include media-breakpoint-up(xl) {
        top: calc(100% + 64px);
      }
    }

    .slick-next {
      right: 0;
      transform: none;
    }

    .slick-prev {
      right: 64px;
      transform: rotate(180deg);
      left: auto;
    }

    .slick-list {
      overflow: visible;
      margin: 0 -12px;
      @include media-breakpoint-up(xl) {
        margin: 0 -16px;
      }
    }
    .slick-track {
      display: flex;
      justify-content: center;
      width: 100% !important;
    }
    .slick-slide {
      height: auto;
      padding: 0 12px;
      > div {
        height: 100%;
      }
      @include media-breakpoint-up(xl) {
        padding: 0 16px;
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &.hasExtraMargin {
    @include media-breakpoint-up(md) {
      padding-bottom: 88px;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: 104px;
    }
  }
}

.title {
  @include text-lg;
  font-weight: 400;
  text-wrap: wrap;
  @include media-breakpoint-up(md) {
    @include text-xl;
    margin-bottom: 8px;
  }
}

.subtitle {
  @include text-sm;
  color: $grey200;
  @include media-breakpoint-up(md) {
    @include text-md;
    margin-bottom: 24px;
  }
}

.linkWrapper {
  display: flex;
  justify-content: flex-end;
}

.cardContainer {
  border: 1px solid $grey25;
  width: 100%;
  min-width: 246px;
  display: flex;
  @include media-breakpoint-up(md) {
    min-width: 340px;
    height: 100%;
  }
  @include media-breakpoint-up(lg) {
    min-width: initial;
  }
}

.linkWrapper {
  a[href^='#'] svg {
    transform: rotate(90deg);
  }
}

.cardImage {
  width: 112px;
  height: 74px;
  flex-shrink: 0;
  > span {
    height: 100% !important;
  }
  @include media-breakpoint-up(md) {
    width: 338px;
    height: 226px;
  }
  @include media-breakpoint-up(lg) {
    width: 297px;
    height: 200px;
  }
  @include media-breakpoint-up(xl) {
    width: 310px;
    height: 208px;
  }
}
