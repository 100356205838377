@import 'styles/import.scss';

.categoriesChoicesWrapper {
  margin: 0 -8px 48px;
  padding: 8px 0;
  display: flex;
  overflow: scroll;
  white-space: nowrap;
  @include hide-scrollbar;
  @include media-breakpoint-up(md) {
    margin: 0 -12px 56px;
  }
}

.categoryChoiceWrapper {
  padding: 0 8px;
  @include media-breakpoint-up(md) {
    padding: 0 12px;
  }
}

.categoryChoice {
  @include tab;
}
