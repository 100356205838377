@import 'styles/import.scss';

.wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  @include media-breakpoint-up(md) {
    gap: 24px;
    grid-template-columns: repeat(6, 1fr);
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(10, 1fr);
    @include make-col(10);
    margin: 0 auto 40px;
  }
  @include media-breakpoint-up(xxl) {
    gap: 36px;
  }
}

.image {
  &.v1 {
    &:first-child {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    &:nth-child(2) {
      grid-column-start: 3;
      grid-column-end: 5;
    }
    &:nth-child(3) {
      grid-column-start: 2;
      grid-column-end: 5;
    }

    @include media-breakpoint-up(md) {
      &:first-child {
        grid-row-start: 1;
        grid-row-end: 3;
        margin-top: 80px;
      }
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 5;
      }
      &:nth-child(3) {
        grid-column-start: 4;
        grid-column-end: 7;
      }
    }
    @include media-breakpoint-up(lg) {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 4;
        margin-top: 80px;
      }
      &:nth-child(2) {
        grid-column-start: 4;
        grid-column-end: 7;
      }
      &:nth-child(3) {
        grid-column-start: 6;
        grid-column-end: 11;
      }
    }
  }
  &.v2 {
    &:first-child {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    &:nth-child(2) {
      grid-column-start: 3;
      grid-column-end: 5;
      margin-top: 48px;
    }
    &:nth-child(3) {
      grid-column-start: 1;
      grid-column-end: 4;
    }
    @include media-breakpoint-up(md) {
      &:nth-child(2) {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 2;
        grid-column-end: 4;
        margin: 0;
      }
      &:nth-child(3) {
        grid-column-start: 4;
        grid-column-end: 7;
        grid-row-start: 1;
        grid-row-end: 3;
        margin-top: 80px;
      }
    }
    @include media-breakpoint-up(lg) {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 4;
      }
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 6;
      }
      &:nth-child(3) {
        grid-column-start: 6;
        grid-column-end: 11;
      }
    }
  }
  &.v3 {
    &:first-child {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    &:nth-child(2) {
      grid-column-start: 3;
      grid-column-end: 5;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    &:nth-child(3) {
      margin-top: 80px;
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    @include media-breakpoint-up(md) {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
      }
      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &:nth-child(3) {
        margin-top: 80px;
        grid-column-start: 5;
        grid-column-end: 7;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
    @include media-breakpoint-up(lg) {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 6;
      }
      &:nth-child(2) {
        grid-column-start: 5;
        grid-column-end: 8;
      }
      &:nth-child(3) {
        grid-column-start: 8;
        grid-column-end: 11;
      }
    }
  }
}
