@import 'styles/import.scss';

.tabsHeadline {
  @include text-sm;
  margin-bottom: 24px;
  @include media-breakpoint-up(md) {
    margin-bottom: 32px;
  }
}

.lecturersWrapper {
  padding-top: 32px;
  min-height: 500px;
  @include media-breakpoint-up(md) {
    padding-top: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    row-gap: 80px;
    margin: 0 -12px;
  }

  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
  }

  @include media-breakpoint-up(xxl) {
    margin: 0 -16px;
  }
}

.labelWrapper {
  padding-top: 16px;
  margin-bottom: 48px;
  border-top: 1px solid $grey25;
  @include media-breakpoint-up(lg) {
    margin-bottom: 56px;
  }
}
