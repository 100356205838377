@import 'styles/import.scss';

.container {
  :global {
    .rbc-button-link {
      cursor: initial;
    }

    .rbc-month-view,
    .rbc-month-row + .rbc-month-row {
      border-color: $grey25;
    }
    .rbc-toolbar {
      display: none;
    }
    .rbc-header {
      @include text-sm;
      font-weight: 400;
      &:nth-child(2) {
        display: none;
      }
    }
    .rbc-agenda-time-cell {
      display: none;
    }
    .rbc-agenda-content {
      border-bottom: 1px solid $grey25;
    }
    .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
      padding: 8px;
      border-color: $grey25;
      border-bottom: 0;
    }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
      padding: 8px;
      border-color: $grey25;
    }
    .rbc-agenda-table {
      background: $lotion;
      @include text-sm;
      th,
      .rbc-agenda-date-cell {
        font-family: $font-mono;
        color: $grey200;
        font-weight: 400;
      }
    }
    .rbc-agenda-view table.rbc-agenda-table {
      border-color: $grey25;
      border-bottom: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    height: 750px;
    :global {
      .rbc-button-link.rbc-show-more {
        @include text-xs;
        color: $green_light;
        font-weight: 600;
        cursor: pointer;
        background-color: transparent;
      }

      .rbc-row-segment {
        padding: 0 8px;
        + .rbc-row-segment {
          margin-top: 4px;
        }
      }
      .rbc-date-cell {
        @include text-lg;
        color: $grey300;
        font-weight: 600;
        padding-top: 4px;
      }
      .rbc-day-bg {
        &.rbc-off-range-bg {
          background-color: $grey5;
        }

        &.rbc-today {
          background: $blue_spot;
        }
      }
      .rbc-date-cell {
        &.rbc-off-range {
          color: $grey25;
        }
      }
      .rbc-header,
      .rbc-day-bg {
        background-color: $grey1;
        border-color: $grey25;
        &:nth-child(6),
        &:nth-child(7) {
          background-color: $white;
        }
      }
      .rbc-header {
        padding: 8px 12px;
        span {
          color: $grey200;
          @include text-sm;
          font-family: $font-mono;
        }
        &:nth-child(2) {
          display: block;
        }
      }
    }
  }
}

.event {
  @include text-xs;
  font-weight: 600;
  border-radius: 4px;
  background: $green_light;
  padding: 4px;
  @include tn(background-color, 0.5s, 0s, ease-in-out);

  &:hover {
    background-color: $green;
  }
  &.isSoldOut {
    background-color: $grey50;
    &:hover {
      background-color: $grey300;
    }
  }
}

.toolbarWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  @include media-breakpoint-up(lg) {
    width: fit-content;
  }
}

.textToolbar {
  color: $green_light;
  font-weight: 600;
  @include text-md;
  cursor: pointer;
  &:hover {
    color: $green;
  }
}

.leftArrow {
  transform: rotate(180deg);
  &.isDisabled {
    pointer-events: none;
    svg * {
      stroke: $grey25;
    }
  }
}

.leftArrow,
.rightArrow {
  &:hover {
    svg * {
      stroke: $green;
    }
  }
}

.title {
  font-weight: 600;
  @include gradient-underline;
  @include tn(color, 0.5s, 0s, ease-in-out);
  &:after {
    background: $green_light;
  }
  &:hover {
    color: $green;
  }
  &.isSoldOut {
    background: linear-gradient(180deg, transparent 0%, $grey50 0),
      linear-gradient(180deg, transparent 0%, $grey300 0);
    background-size: 100% 1px, 0 1px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
  }
}

.currentMonth {
  @include text-xl;
  color: $black;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
