@import 'styles/import.scss';

.section {
  padding-top: 16px;
  border-top: 1px solid $grey25;
}

.variationWrapper {
  @include media-breakpoint-up(md) {
    @include make-col(4, 6);
    margin-left: auto;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(6);
  }
}

.videoWrapper {
  margin-bottom: 80px;
}

.text {
  @include text-md;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    @include gradient-underline;
    color: $black;

    &::before {
      border-color: $green_light;
    }
  }
}

.linkList {
  margin-top: 48px;
  border-top: 1px solid $grey25;
}

.button {
  margin-top: 32px;
}

.wrapper {
  &.isRow {
    margin-top: 48px;
    :local(.title) {
      @include heading-5;
      margin-bottom: 24px;
    }
  }
  &.isColumn {
    margin-top: 40px;
    :local(.title) {
      @include heading-3;
      margin-bottom: 48px;
    }
  }
  @include media-breakpoint-up(md) {
    &.isRow,
    &.isColumn {
      margin-top: 48px;
    }
    &.isRow {
      :local(.title) {
        margin-bottom: 48px;
        @include make-col(4, 6);
      }
    }
    &.isColumn {
      :local(.title) {
        margin-bottom: 80px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.isRow {
      display: flex;
      :local(.title) {
        @include make-col(5, 12);
      }
    }
    &.isColumn {
      :local(.title) {
        @include make-col(10, 12);
      }
    }
    &.isRow,
    &.isColumn {
      margin-top: 56px;
    }
  }
  @include media-breakpoint-up(xl) {
    &.isColumn {
      :local(.title) {
        @include make-col(8, 12);
      }
    }
  }
}

.accordions {
  margin-top: 64px;
  border-top: 1px solid $grey25;
}

.titleLink {
  margin-top: 40px;
}

.historyWrapper {
  margin-top: 64px;
  @include media-breakpoint-up(md) {
    margin-top: 80px;
  }
}
