@import 'styles/import.scss';

.title {
  @include heading-6;
  margin-bottom: 56px;
  @include media-breakpoint-up(md) {
    margin-bottom: 48px;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(3);
    margin-bottom: 0;
    margin-left: 0;
  }
}

.subtitleWrapper {
  @include text-xs;
  color: $grey200;
  i {
    background: $grey200;
  }
  div {
    &:last-child {
      &::before {
        background: $grey200;
      }
    }
  }
}

.courseWrapper {
  padding-top: 28px;

  @include media-breakpoint-up(md) {
    padding-top: 32px;
  }
}

.link {
  @include text-xl;
  @include green-underline;
  padding-bottom: 28px;
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $green_light;

  div,
  i {
    @include tn((color, background-color), 0.5s, 0s, ease-in-out);
  }
  &:hover {
    div {
      color: $green;
    }
    i {
      background-color: $green;
    }
    svg * {
      fill: $green;
    }
  }

  @include media-breakpoint-up(md) {
    padding-bottom: 32px;
  }
}

.wrapper {
  border-top: 1px solid $grey25;
  @include media-breakpoint-up(lg) {
    @include make-col(8);
  }
}

.linkList {
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: flex-end;
    column-gap: 24px;
  }
  @include media-breakpoint-up(xxl) {
    column-gap: 32px;
  }
}
