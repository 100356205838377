@import 'styles/import.scss';

.title {
  margin-bottom: 56px;
  @include heading-6;
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
  @include media-breakpoint-up(lg) {
    @include make-col-offset(1);
  }
}

.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 20px;
  @include media-breakpoint-up(md) {
    @include make-col(4, 6);
    gap: 40px 24px;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8, 12);
  }
  @include media-breakpoint-up(xl) {
    gap: 48px 24px;
  }
  @include media-breakpoint-up(xxl) {
    gap: 64px 32px;
  }
}

.cardContainer {
  border: 1px solid $grey25;
  position: relative;
  width: calc(50% - 10px);
  aspect-ratio: 4/3;
  @include media-breakpoint-up(md) {
    width: calc(50% - 12px);
  }
  @include media-breakpoint-up(lg) {
    width: calc(33.33333334% - 16px);
  }
  @include media-breakpoint-up(xl) {
    width: calc(33.33333334% - 22px);
    height: 186px;
  }
  @include media-breakpoint-up(xxl) {
    height: 200px;
  }
}

.cardText {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: $grey5;
  padding: 2px 4px;
  @include caption-b;
  @include media-breakpoint-up(md) {
    padding: 2px 6px;
  }
  @include media-breakpoint-up(xl) {
    padding: 4px 8px;
    @include text-xs;
  }
}

.image {
  min-width: 100%;
  aspect-ratio: 4/3;
  padding: 0 20px;
  > span {
    height: 100% !important;
  }
  @include media-breakpoint-up(md) {
    padding: 0 40px;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 32px;
  }
}

.imageWrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.wrapper {
  @include media-breakpoint-up(md) {
    display: flex;
    gap: 24px;
    justify-content: space-between;
  }
}
