@import 'styles/import.scss';

.title {
  @include heading-3;
  margin-bottom: 64px;
  @include media-breakpoint-up(md) {
    @include make-col-offset(1, 6);
    @include make-col(4, 6);
    margin-bottom: 80px;
  }
  @include media-breakpoint-up(lg) {
    @include make-col-offset(1, 12);
    @include make-col(9, 12);
    margin-bottom: 80px;
  }
}

.text {
  @include text-lg;
  margin-bottom: 40px;
  color: $chinese_black;
  a {
    color: $black;
    @include gradient-underline;
    &:hover {
      color: $green;
    }
  }
}

.textWrapper {
  @include make-col-offset(1, 4);
  @include media-breakpoint-up(md) {
    margin-left: auto;
    @include make-col(4, 6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(6);
  }
}

.textImageWrapper {
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.image {
  margin-top: 80px;
  @include make-col(3, 4);

  @include media-breakpoint-up(md) {
    @include make-col(3, 6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(5, 12);
    margin-top: 160px;
  }
}
