@import 'styles/import.scss';

.isHidden {
  display: none;
}

.fileWrapper {
  position: relative;
  display: flex;
  align-items: center;
  background: $grey1;
  padding: 0 8px;
}

.container {
  @include green-underline;
  &:hover {
    label,
    :local(.filePlaceholder) {
      color: $green;
    }
    svg * {
      fill: $green;
    }
  }
}

.isError {
  color: $red_error;

  label,
  :local(.filePlaceholder) {
    color: $red_error;
  }

  :local(.filePlaceholder) {
    color: $red_error;
    svg * {
      fill: $red_error;
    }
  }

  &:hover {
    :local(.filePlaceholder) {
      color: $red_error_hover;
      svg * {
        fill: $red_error_hover;
      }
    }
    label {
      color: $red_error;
    }
  }

  :local(.container) {
    &::after {
      background: $red_error;
    }
    &::before {
      background: $red_error_hover;
    }
  }
}

.delete {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.file {
  position: absolute;
  opacity: 0;
  top: 0;
  &::file-selector-button {
    cursor: pointer;
  }
}

.filePlaceholder {
  @include tn(color, 0.5s, 0s, ease-in-out);

  height: 48px;
  width: 100%;
  @include text-md;
  color: $green_light;
  font-weight: 600;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    * {
      @include tn(fill, 0.5s, 0s, ease-in-out);
    }
  }
}

.showLabel {
  @include text-xs;
  color: $grey200;
  margin-top: 6px;
}
