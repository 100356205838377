@import 'styles/import.scss';

.banner {
  padding: 32px 32px 64px;
  background-color: $blue_light;
  color: $white;
  @include media-breakpoint-up(lg) {
    padding: 64px 64px 80px;
  }
}

.subtitle {
  font-family: $font-mono;
  @include text-sm;
}

.title {
  max-width: 880px;
  @include heading-4;
  margin-top: 12px;
}
