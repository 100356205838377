@import 'styles/import.scss';

.lecturersWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 64px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 80px;
    justify-content: flex-end;
    margin: 0 -12px;
  }

  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
  }

  @include media-breakpoint-up(xxl) {
    margin: 0 -16px;
  }
}

.buttonWrapper {
  margin-top: 48px;
  svg {
    transform: rotate(90deg);
  }
}
