@import 'styles/import.scss';

.categoriesWrapper {
  margin-bottom: 48px;
  border-top: 1px solid $grey25;
  &.isCheckout {
    margin-bottom: 40px;
  }
  @include media-breakpoint-up(lg) {
    border: 0;
  }
}

.variationDescription {
  display: block;
  color: $green_light;
  font-weight: 600;
}

.header {
  display: none;
  font-family: $font-mono;
  color: $grey200;
  width: 100%;
  @include text-xs;
  @include media-breakpoint-up(lg) {
    display: table-row;
    margin-bottom: 16px;
    border-bottom: 1px solid $grey25;
    &:not(&.isCheckout) {
      &::before {
        content: '';
      }
    }
    &.removeGreenUnderline {
      &::after,
      &::before {
        display: none !important;
      }
    }
    th {
      font-weight: 400;
      padding-bottom: 16px;
      padding-left: 16px;
      &:first-child {
        padding-left: 0px;
      }
    }
  }
}

.subcategoryWrapper {
  padding: 28px 0;
  display: block;
  border-bottom: 1px solid $grey25;

  &:not(&.isCheckout) {
    @include green-underline;
    opacity: 0;
    visibility: hidden;
    animation: fadeIn 1.5s forwards;
    cursor: pointer;
    &:hover {
      color: $green;
      @include tn(color, 0.5s, 0s, ease-in-out);

      :local(.subtitleWrapper) {
        color: $green;
        @include tn(color, 0.5s, 0s, ease-in-out);

        i {
          background-color: $green;
        }
      }
    }
  }

  &.isCheckout {
    border-bottom: 1px solid $grey25;
  }

  td svg {
    display: inline-block;
    vertical-align: sub;
    margin-right: 4px;
    path {
      fill: $blue_light;
    }
  }
  @include media-breakpoint-up(md) {
    padding: 32px 0;
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
  }
  @include media-breakpoint-up(lg) {
    display: table-row;
    border-bottom: 1px solid $grey25;
    &.removeGreenUnderline {
      &::after,
      &::before {
        display: none !important;
      }
    }

    td {
      min-width: 9%;
    }

    td:first-child {
      width: 35%;
    }
    td:nth-child(2) {
      width: 18%;
    }

    td:last-child {
      width: 12%;
    }

    &.isCheckout {
      :local(.price) {
        min-width: 80px;
        text-align: right;
      }
    }
  }
}

.textWrapper {
  @include text-md;
  margin-bottom: 4px;
  display: block;
  &.isBold {
    font-weight: 700;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 32px;
  }
  @include media-breakpoint-up(lg) {
    display: table-cell;
    margin-right: 0px;
    margin-bottom: 0;
    padding: 32px 0 32px 16px;
  }
}

.price,
.textWrapper {
  span {
    display: none;
  }
  @include media-breakpoint-up(md) {
    span {
      @include text-xs;
      color: $grey200;
      display: block;
      font-family: $font-mono;
      margin-bottom: 6px;
    }
  }
  @include media-breakpoint-up(lg) {
    span {
      display: none;
    }
  }
}

.titleWrapper {
  margin-bottom: 12px;
  display: block;
  @include media-breakpoint-up(md) {
    margin-bottom: 20px;
    width: 100%;
  }
  @include media-breakpoint-up(lg) {
    display: table-cell;
    padding-right: 24px;
    margin-bottom: 0;
    width: initial;
  }
  @include media-breakpoint-up(xl) {
    padding-right: 32px;
  }
}

.subtitleWrapper {
  @include text-xs;
  color: $grey200;
  margin-bottom: 8px;
  i {
    background: $grey200;
  }
  div {
    &:last-child {
      &::before {
        background: $grey200;
      }
    }
  }
}

.arrowWrapper,
.buttonWrapper {
  margin-top: 24px;
  @include media-breakpoint-up(md) {
    @include make-col-offset(1, 6);
    margin-top: 0;
  }
  @include media-breakpoint-up(lg) {
    margin-left: 12px;
  }
  @include media-breakpoint-up(xl) {
    margin-left: 16px;
  }
}

.hide {
  &:not(&.isCheckout) {
    visibility: hidden;
    pointer-events: none;
    height: 0;
  }
  &.isCheckout {
    font-family: $font-mono;
    color: $grey200;
    @include text-xs;
    @include media-breakpoint-up(lg) {
      min-width: 80px;
      text-align: right;
    }
  }
}

.headerWrapper {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.price {
  white-space: nowrap;
}

.hiddenButton {
  min-width: 128px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
