@import 'styles/import.scss';

.label {
  @include text-xl;
  margin-bottom: 16px;
  @include media-breakpoint-up(md) {
    padding: 0 16px;
  }
}

.description {
  @include text-md;
  margin-bottom: 40px;
  &:global(.has--extra-margin) {
    margin-bottom: 64px;
  }
  @include media-breakpoint-up(md) {
    padding: 0 16px;
  }
}
