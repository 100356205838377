@import 'styles/import.scss';

.icon {
  margin-bottom: 20px;
  color: $blue_light;
  span {
    font-size: 40px;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
    @include make-col(2, 6);
    padding: 0 12px;
    span {
      font-size: 44px;
    }
  }
  @include media-breakpoint-up(lg) {
    @include make-col(2, 8);
  }
  @include media-breakpoint-up(xxl) {
    padding: 0 16px;
    span {
      font-size: 48px;
    }
  }
}

.title {
  @include heading-6;
  margin-bottom: 16px;
}

.textContainer {
  @include media-breakpoint-up(md) {
    @include make-col(4, 6);
    padding: 0 12px;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(6, 8);
  }
  @include media-breakpoint-up(xxl) {
    padding: 0 16px;
  }
}

.text {
  @include text-md;
  @include formatted-li;

  p {
    margin: 0;
  }
}

.principle {
  padding: 32px 0 48px;
  border-bottom: 1px solid $grey25;
  @include media-breakpoint-up(md) {
    display: flex;
    margin: 0 -12px;
  }
  @include media-breakpoint-up(xxl) {
    margin: 0 -16px;
  }
}

.wrapper {
  border-top: 1px solid $grey25;
  @include media-breakpoint-up(lg) {
    margin-left: auto;
    @include make-col(8);
  }
}
