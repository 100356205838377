@import 'styles/import.scss';

.imageTextWrapper {
  margin-bottom: 60px;
  @include media-breakpoint-up(md) {
    @include make-col(3, 6);

    padding: 0 12px;
    margin-bottom: 0;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4, 12);

    &:nth-last-child(-n + 3) {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-up(xxl) {
    padding: 0 16px;
  }
}

.image {
  display: block;
  @include make-col(3, 4);
  margin-left: auto;
  margin-bottom: 32px;
  @include media-breakpoint-up(md) {
    margin-left: auto;
    @include make-col(2, 3);
  }
  @include media-breakpoint-up(lg) {
    max-width: initial;
  }
}

.textWrapper {
  max-width: 420px;
}

.name {
  @include text-xl;
  margin-bottom: 4px;
  cursor: pointer;

  + .role {
    margin-top: 8px;
  }
  a {
    color: $black;
    border-bottom: 1px solid $green_light;
    &:hover {
      color: $green_light;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 6px;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 8px;
  }
}

.telephoneEmailWrapper {
  margin-top: 20px;
}

.telephone,
.email {
  @include text-md;
}

.role {
  @include text-md;
  color: $grey200;
}

.name,
.email {
  @include gradient-underline;
  @include tn((background-size, color), 0.5s, 0s, ease-in-out);

  &:hover {
    color: $green;
  }
}

.email,
.name {
  a {
    @include tn(color, 0.5s, 0s, ease-in-out);
    color: $black;
    &:hover {
      color: $green;
    }
  }
}
