@mixin accordion-plus-icon {
  position: relative;
  width: 22px;
  flex-shrink: 0;
  &::after {
    content: '';
    display: block;
    width: 22px;
    height: 2px;
    background-color: $green_light;
    position: absolute;
  }
  &::before {
    content: '';
    display: block;
    width: 22px;
    height: 2px;
    background-color: $green_light;
    transform: rotate(-90deg);
    position: absolute;
    top: 50%;
    transition: transform 0.25s ease-out;
  }
}

@mixin accordion-plus-icon-open {
  &::before {
    transform: rotate(0deg);
  }
}
