@import 'styles/import.scss';

.button {
  width: 100%;
  min-width: 128px;
  &:active {
    pointer-events: none;
  }
  @include media-breakpoint-up(md) {
    width: initial;
  }
}

.buttonWrapper {
  display: block;
  margin-top: 24px;
  @include media-breakpoint-up(md) {
    width: 100%;
    margin-top: 0;
  }
  @include media-breakpoint-up(lg) {
    width: initial;
    display: table-cell;
    text-align: end;
  }
}
