@import 'styles/import.scss';

.latestPosts {
  padding: 40px 0;
  background-color: $grey1;
  @include media-breakpoint-up(md) {
    padding: 48px 0 40px;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: 80px;
  }
}

.wrapper {
  @include media-breakpoint-up(lg) {
    @include make-col(8, 12);
    margin-left: auto;
  }
}

.container {
  border-top: 1px solid $grey25;
  margin-bottom: 48px;
}

.post {
  padding: 40px 0;
  border-bottom: 1px solid $grey25;
  @include media-breakpoint-up(md) {
    display: flex;
    gap: 32px;
  }
}

.image {
  width: 246px;
  margin-left: auto;
  margin-bottom: 32px;
  height: 185px;
  > span {
    height: 100% !important;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
    margin-left: 0;
    width: 192px;
    flex: 0 0 auto;
    max-width: initial;
    height: 144px;
  }
  @include media-breakpoint-up(xl) {
    width: 200px;
    height: 150px;
  }
  @include media-breakpoint-up(xxl) {
    width: 212px;
    height: 160px;
  }
}

.postSubtitle {
  margin-bottom: 8px;
  color: $grey200;
  @include text-xs;
  i {
    background: $grey200;
  }
}

.postTitle {
  color: $black;
  @include text-xl;
  @include gradient-underline;
  @include tn(color, 0.5s, 0s, ease-in-out);
  &:after {
    background: $green_light;
  }
  &:hover {
    color: $green;
  }
}
