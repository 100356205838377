@import 'styles/import.scss';

.button {
  svg {
    transform: rotate(90deg);
  }
}

.title {
  @include heading-5;
  @include media-breakpoint-up(md) {
    @include make-col(4, 6);
  }
  @include media-breakpoint-up(lg) {
    max-width: initial;
  }
}

.table {
  margin-top: 120px;
}

.text {
  @include text-md;
  color: $chinese_black;
  a {
    color: $black;
    @include gradient-underline;
    &:hover {
      color: $green;
    }
  }
}

.textWrapper {
  margin-top: 48px;
  @include media-breakpoint-up(md) {
    margin-top: 80px;

    margin-left: auto;
    @include make-col(4, 6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(6);
  }
}
