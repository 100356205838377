@import 'styles/import.scss';

.title {
  @include heading-3;
  margin-bottom: 48px;
  @include media-breakpoint-up(md) {
    padding: 0 16px;
  }
}

.subtitleLabel {
  color: $grey200;
  margin-bottom: 8px;
  @include text-sm;
  @include media-breakpoint-up(md) {
    padding: 0 16px;
  }
}

.subtitle {
  @include heading-5;
  margin-bottom: 32px;
  @include media-breakpoint-up(md) {
    padding: 0 16px;
  }
}

.formContainer {
  @include media-breakpoint-up(md) {
    margin: 0 -16px;
  }
}

.gravityForm {
  &:after {
    content: '';
    position: fixed;
    top: 0;
    background-color: rgba($blue_dark, 0.95);
    opacity: 0;
    @include tn((opacity), (0.5s), (0s), (ease-in-out));
    width: 100%;
    z-index: 101;
    height: 100%;
    pointer-events: none;
  }
  &.isActive {
    overflow-y: scroll;
    &::after {
      height: 100%;
      z-index: 101;
      width: 100%;
      opacity: 1;
    }
  }
}

.feedback {
  margin-bottom: 64px;
  h3 {
    @include heading-3;
    margin-bottom: 32px;
  }
}

.buttonWrapper {
  &.isLoading {
    > div {
      width: 120px;
      height: 120px;
    }
  }
  @include media-breakpoint-up(md) {
    padding: 0 16px;
  }
}

.fadeIn {
  animation: fadeIn 0.5s forwards;
}

.fadeOut {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
  to {
    z-index: 10;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  from {
    visibility: visible;
    z-index: 10;
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
  }
}
