@import 'styles/import.scss';

.videoWrapper {
  @include media-breakpoint-up(sm) {
    max-width: 560px;
    margin: 0 auto;
  }
  @include media-breakpoint-up(md) {
    @include make-col(8, 10);
    margin: 40px auto;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
  }
}
