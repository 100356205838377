@import 'styles/import.scss';

.title {
  @include heading-6;
  margin-bottom: 64px;
  @include media-breakpoint-up(md) {
    @include make-col(3);
  }
}

.contactsWrapper {
  row-gap: 64px;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    row-gap: 80px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    margin-left: auto;
    > div {
      @include make-col(6, 12);
    }
  }
}

.wrapper {
  padding: 48px 0 8px;
  border-top: 1px solid $grey25;

  @include media-breakpoint-up(lg) {
    padding: 56px 0 0;
    display: flex;
  }
}

.contacts {
  @include media-breakpoint-up(md) {
    > div {
      padding-bottom: 80px;
    }
    + :not(.contacts) {
      padding-top: 80px;
    }
  }
}
