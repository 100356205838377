@import 'styles/import.scss';

.allPosts {
  padding-top: 16px;
  border-top: 1px solid $grey25;
}

.title {
  @include heading-3;
  margin: 40px 0 80px;
  @include media-breakpoint-up(md) {
    margin: 56px 0 120px;
  }
}

.wrapper {
  @include media-breakpoint-up(lg) {
    display: flex;
    gap: 24px;
    @include make-col(11, 12);
    margin-left: auto;
  }
  @include media-breakpoint-up(xl) {
    gap: 32px;
  }
}

.sidebar {
  @include media-breakpoint-up(lg) {
    @include make-col(3, 11);
    position: sticky;
    height: fit-content;
    top: calc($header-height + 64px);
  }
}

.totalCount {
  margin-bottom: 16px;
  color: $green_light;
  @include heading-1;
  @include media-breakpoint-up(md) {
    margin-bottom: 24px;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 32px;
  }
}

.filterText {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
    @include text-sm;
    margin-bottom: 24px;
  }
}

.categoriesWrapper {
  margin: 0 -16px 48px;
  display: flex;
  align-items: center;
  overflow: scroll;
  white-space: nowrap;
  @include hide-scrollbar;
  gap: 16px;
  padding: 0 16px;
  @include media-breakpoint-up(md) {
    gap: 24px;
    margin-bottom: 64px;
  }
  @include media-breakpoint-up(lg) {
    flex-direction: column;
    padding: 0;
    align-items: flex-start;
    margin: 0;
  }
}

.tab {
  @include tab;
}

.select {
  @include tab;
  padding-right: 64px;
  appearance: none;
  position: relative;
  min-width: 156px;
}

.selectWrapper {
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
    background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_3405_812" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20"><rect width="20" height="20" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_3405_812)"><path d="M9.99935 12.5417L5.29102 7.8125L6.16602 6.9375L9.99935 10.7708L13.8327 6.9375L14.7077 7.8125L9.99935 12.5417Z" fill="%232FBD77"/></g></svg>');
    width: 20px;
    height: 20px;
    background-size: cover;
    pointer-events: none;
    @include tn(transform, 0.2s, 0s, ease-in-out);
  }
  &:hover {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_3405_26626' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='20' height='20'%3E%3Crect width='20' height='20' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_3405_26626)'%3E%3Cpath d='M10.0003 12.5417L5.29199 7.8125L6.16699 6.9375L10.0003 10.7708L13.8337 6.9375L14.7087 7.8125L10.0003 12.5417Z' fill='%23147b48'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
  &.isSelected {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_3405_26626' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='20' height='20'%3E%3Crect width='20' height='20' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_3405_26626)'%3E%3Cpath d='M10.0003 12.5417L5.29199 7.8125L6.16699 6.9375L10.0003 10.7708L13.8337 6.9375L14.7087 7.8125L10.0003 12.5417Z' fill='%23ffffff'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
  &.isOpen {
    &::after {
      transform: rotate(180deg);
    }
  }
  @include media-breakpoint-up(lg) {
    &.isSelected {
      &:hover {
        background-color: $green;
      }
    }
  }
}

.dropDownWrapper {
  display: none;
  flex-direction: column;
  box-shadow: 4px 8px 20px 0px rgba(14, 14, 15, 0.12),
    0px 0px 4px 0px rgba(14, 14, 15, 0.08);
  position: absolute;
  min-width: 120px;
  max-height: 254px;
  top: calc(100% + 8px);
  right: 0;
  overflow-y: auto;
  background-color: $white;
  color: $green_light;
  z-index: 2;
  div {
    padding: 12px 16px;
    border-bottom: 1px solid $grey25;
    &:last-child {
      border-bottom: 0;
    }
    &:hover {
      color: $green;
    }
  }

  &.isOpen {
    display: flex;
  }
}

.showMore {
  @include text-sm;
  color: $green_light;
  cursor: pointer;
  &:hover {
    color: $green;
  }
}

.postImage {
  width: 246px;
  margin-left: auto;
  margin-bottom: 32px;
  height: 185px;
  cursor: pointer;
  > span {
    height: 100% !important;
  }
  @include media-breakpoint-up(md) {
    width: 192px;
    height: 144px;
    flex: initial;
    max-width: initial;
    margin: 0;
    flex-shrink: 0;
  }
  @include media-breakpoint-up(lg) {
    width: 200px;
    height: 150px;
  }
}

.postSubtitle {
  @include text-xs;
  color: $grey200;
  margin-bottom: 8px;
  i {
    background-color: $grey200;
  }
}

.slideTitle {
  color: $black;
  @include text-xl;
  @include gradient-underline;
  @include tn(color, 0.5s, 0s, ease-in-out);
  &:after {
    background: $green_light;
  }
  &:hover {
    color: $green;
  }
}

.post {
  padding: 40px 0;
  border-top: 1px solid $grey25;
  &:last-child {
    border-bottom: 1px solid $grey25;
  }
  @include media-breakpoint-up(md) {
    display: flex;
    gap: 32px;
  }
}

.postsWrapper {
  width: 100%;
}

.loading {
  > div {
    width: auto;
    height: auto;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 48px;
  button {
    &:first-child {
      transform: rotate(180deg);
    }
  }
}
