@import 'styles/import.scss';

.link {
  padding: 26px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  @include text-md;
  font-weight: 600;
  @include green-underline;
  a {
    color: $green_light;
  }
  &:hover {
    color: $green;
    a {
      color: $green;
    }
    svg * {
      fill: $green;
      // stroke: $green;
    }
  }
  @include media-breakpoint-up(md) {
    padding: 30px 0;
  }
}
