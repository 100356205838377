@import 'styles/import.scss';

.title {
  margin-bottom: 8px;
  span {
    color: $blue_light;

    font-size: 40px;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
    @include make-col(2, 6);
    span {
      font-size: 44px;
    }
  }
  @include media-breakpoint-up(lg) {
    @include make-col(2, 8);
  }
  @include media-breakpoint-up(xxl) {
    span {
      font-size: 48px;
    }
  }
}

.subtitle {
  @include media-breakpoint-up(md) {
    @include make-col(3, 6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(5, 8);
  }
}

.subtitle,
.title {
  @include text-xl;
}

.cardWrapper {
  padding: 32px 0;
  @include green-underline;
}

.titleWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  &.isActive {
    :local(.icon) {
      @include accordion-plus-icon-open;
    }
  }
}
.titleContainer {
  &:hover {
    :local(.title),
    :local(.subtitle) {
      color: $green;
      transition: color, 0.5s, 0s, ease-in-out;
    }
    :local(+ .icon) {
      &::before,
      &::after {
        background-color: $green;
      }
    }
  }
  @include media-breakpoint-up(md) {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }
}

.icon {
  @include accordion-plus-icon;
  margin-top: 18px;
}

.date {
  @include text-lg;
  margin-bottom: 8px;
}

.text {
  @include text-md;
  a {
    @include gradient_underline;
    color: $black;
    &:after {
      background: $green_light;
    }
  }
}

.dateCardWrapper {
  padding: 32px 0;
  border-bottom: 1px solid $grey25;
  &:first-child {
    padding-top: 24px;
  }
  &:last-child {
    border: 0;
  }
}

.dateCardsWrapper {
  @include media-breakpoint-up(md) {
    @include make-col(4, 6);
    margin-left: auto;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(6, 8);
  }
}

.cardWrapper {
  border-top: 1px solid $grey25;
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    margin-left: auto;
  }
}
