@import 'styles/import.scss';

.labelWrapper {
  margin-bottom: 48px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 56px;
  }
}

.heading {
  @include heading-3;
  margin-bottom: 32px;
  @include media-breakpoint-up(md) {
    @include make-col(5, 6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(10, 12);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(8, 12);
  }
}

.header {
  margin-bottom: 80px;
  @include media-breakpoint-up(md) {
    margin-bottom: 120px;
  }
}
