@import 'styles/import.scss';
.title {
  @include heading-6;
  margin-bottom: 56px;
  @include media-breakpoint-up(md) {
    @include make-col(2, 6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(3);
    margin-left: auto;
  }
}

.countsWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 64px;
  @include media-breakpoint-up(md) {
    margin: 0 -12px;
    @include make-col(4, 6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
  }
}

.countWrapper {
  width: 100%;
  @include media-breakpoint-up(md) {
    padding: 0 12px;
    width: 50%;
  }
}

.countContainer {
  padding-top: 32px;
  border-top: 1px solid $grey25;
}

.count {
  @include heading-2;
  margin-bottom: 8px;
  color: $blue_light;
}

.icon {
  color: $blue_light;
  margin-bottom: 8px;
  span {
    font-size: 40px;
  }
  @include media-breakpoint-up(md) {
    span {
      font-size: 44px;
    }
  }
  @include media-breakpoint-up(xxl) {
    span {
      font-size: 48px;
    }
  }
}

.label {
  @include text-md;
}

.wrapper {
  @include media-breakpoint-up(md) {
    display: flex;
    row-gap: 24px;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: 40px;
  }
}

.headline {
  margin-bottom: 16px;
  @include heading-6;
}
