@import 'styles/import.scss';

.quoteWrapper {
  margin-bottom: 40px;
}

.textWrapper {
  display: flex;

  &::before {
    content: '“';
    @include heading-6;
    color: $blue_light;
    margin-right: 2px;
  }

  @include media-breakpoint-up(lg) {
    @include make-col(6);
    margin-left: auto;
    &::before {
      margin-right: 4px;
    }
  }
}

.quote {
  color: $blue_light;
  @include heading-6;
  margin-bottom: 24px;
}

.name {
  @include text-sm;
  margin-bottom: 48px;
  div {
    color: $grey200;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.imageWrapper {
  @include make-col(2, 4);
  margin-left: auto;
  margin-top: 48px;

  @include media-breakpoint-up(md) {
    @include make-col(2, 6);
    margin-top: 120px;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4);
    margin-right: auto;
    margin-left: 0;
  }
}

.quoteContainer {
  margin-top: 40px;
  @include media-breakpoint-up(md) {
    margin-top: 120px;
    display: flex;
    column-gap: 26px;
  }
  @include media-breakpoint-up(lg) {
    column-gap: 24px;
  }
  @include media-breakpoint-up(xxl) {
    column-gap: 32px;
  }
}

:global(.is--full-width) {
  .quoteWrapper {
    margin-bottom: 0;
    > div {
      padding-bottom: 64px;
      @include media-breakpoint-up(md) {
        padding-bottom: 96px;
      }
      @include media-breakpoint-up(lg) {
        padding-bottom: 112px;
      }
      @include media-breakpoint-up(xl) {
        padding-bottom: 120px;
      }
    }
  }
  .textWrapper {
    @include media-breakpoint-up(md) {
      max-width: 560px;
      margin: 0 auto;
    }
    @include media-breakpoint-up(lg) {
      @include make-col(8, 12);
    }
  }
}
