@import 'styles/import.scss';

.courseContactPerson {
  padding-top: 16px;
  border-top: 1px solid $grey25;
}

.title {
  margin-top: 48px;
  margin-bottom: 80px;
  @include heading-5;
  @include media-breakpoint-up(md) {
    @include make-col(5, 6);
    margin-bottom: 120px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 56px;
    @include make-col(9);
  }
}

.imageTextWrapper {
  @include media-breakpoint-up(md) {
    @include make-col(3, 6);
    margin-left: auto;
  }
  @include media-breakpoint-up(lg) {
    max-width: initial;
    display: flex;
    margin: 0 -12px;
  }
  @include media-breakpoint-up(xxl) {
    margin: 0 -16px;
  }
}

.image {
  display: block;
  @include make-col(3, 4);
  margin-left: auto;
  margin-bottom: 32px;
  @include media-breakpoint-up(md) {
    @include make-col(2, 3);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4, 12);
    padding: 0 12px;
    margin-left: 0;
    margin-bottom: 0;
  }
  @include media-breakpoint-up(xxl) {
    padding: 0 16px;
  }
}

.textWrapper {
  max-width: 420px;
  div {
    width: fit-content;
  }
  @include media-breakpoint-up(lg) {
    margin-left: 48px;
    padding: 0 12px;
  }
  @include media-breakpoint-up(xxl) {
    padding: 0 16px;
  }
}

.name {
  @include text-xl;
}

.role {
  @include text-md;
  color: $grey200;
  margin-bottom: 20px;
  margin-top: 8px;
}

.telephone,
.email {
  @include text-md;
}

.name,
.email {
  @include gradient-underline;
  @include tn(color, 0.5s, 0s, ease-in-out);

  &:hover {
    color: $green;
  }
}

.email,
.name {
  a {
    @include tn(color, 0.5s, 0s, ease-in-out);
    color: $black;
    &:hover {
      color: $green;
    }
  }
}

.lecturersWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 64px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 80px;
    justify-content: flex-end;
    margin: 0 -12px;
  }

  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
  }

  @include media-breakpoint-up(xxl) {
    margin: 0 -16px;
  }
}
