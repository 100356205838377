.columns {
  @apply flex gap-8;

  &.columnStacked {
    @apply flex-col md:flex-row;
  }

  &.alignCenter {
    & .column {
      @apply justify-center;
    }
  }

  &.alignBottom {
    & .column {
      @apply justify-end;
    }
  }

  &.hasBackground {
    @apply p-8;
  }

  & .column {
    @apply flex flex-col w-full;

    &.hasBackground {
      @apply p-4;
    }
  }
}
